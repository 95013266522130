import { TablePriceHeader, TablePriceWrapper, TablePriceBody } from './styles';
import { formatCurrency } from '../../utils/global';

const TablePriceOffers = ({ data }: any) => {
  return (
    (data || [])?.map((item: any, index: number) => (
      <TablePriceWrapper key={index}>
        <TablePriceHeader>Цінова {++index}</TablePriceHeader>
        <TablePriceBody>{formatCurrency(item?.total)}</TablePriceBody>
      </TablePriceWrapper>
    ))
  )
};

export default TablePriceOffers;