import styled from 'styled-components';
import { FC, useState } from 'react';
import { Colors } from '../colors';

export const OrderTrackingContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  @media ((min-width: 768px) and (max-width: 1023px)) {
    min-width: 31.5em;
  }
  @media (max-width: 767px) {
    min-width: 19.75em;
  }
  @media (max-width: 480px) {
    min-width: fit-content;
  }
`;
export const Label = styled.div`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
  color: ${Colors.primary};
  padding: 0 0.75em 0.75em 0.75em;

  @media ((min-width: 1024px) and (max-width: 1199px)) {
    font-size: 0.75rem;
  }
`;
export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: ${Colors.primary80};
  border-radius: 0.25em;
  border: 1px solid ${Colors.periwinkle};
  gap: 0.75rem;

  @media ((min-width: 768px) and (max-width: 1023px)) {
    padding: 1em 0.75em;
  }

  @media (max-width: 767px) {
    padding: 0.75em;
  }
`;
export const Status = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1439px) {
    width: 18.25em;
  }

  @media ((min-width: 768px) and (max-width: 1023px)) {
    width: 88%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-width: 25.5em;
    margin-right: 0.75em;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    min-width: fit-content;
    width: 100%;
  }
`;
export const StatusText = styled.span`
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  color: ${Colors.white};

  @media ((min-width: 1024px) and (max-width: 1199px)) {
    font-size: 0.6rem;
  }

  @media (max-width: 767px) {
    font-size: 0.875em;
    line-height: 1.25em;
    text-transform: none;
  }
`;
export const StatusDate = styled.span`
  font-size: 0.75em;
  font-weight: 400;
  line-height: 0.75em;
  color: ${Colors.white80};

  @media ((min-width: 768px) and (max-width: 1023px)) {
    margin-top: 0.625em;
  }
  @media (max-width: 767px) {
    margin-top: 0.25em;
  }
`;
export const ExpandWrapper = styled.div`
  display: flex;
  gap: 1.5em;
`;

export const ExpandHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 0.75rem;
`;

export const ExpandContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IExpandedProps {
  compact: boolean;
}

export const ExpandedOrderTrackingContainer = styled.div<IExpandedProps>`
  position: ${props => (props.compact ? 'relative' : 'absolute')};
  width: 100%;
  max-width: ${props => (props.compact ? '100%' : '57rem')};
  box-shadow: 0 0 33px 0 #00000017;
  top: ${compact => (compact ? '10px' : '0')};
  z-index: 1002;

  @media (min-width: 1024px) {
    top: ${props => (props.compact ? '0' : '50%')};
    left: ${props => (props.compact ? '0' : '50%')};
    transform: ${props =>
    props.compact ? 'translate(0, 0)' : 'translate(-50%, -50%)'};
  }
  @media ((min-width: 768px) and (max-width: 1023px)) {
    max-width: 70%;
    top: ${props => (props.compact ? '0' : '50%')};
    left: ${props => (props.compact ? '0' : '50%')};
    transform: ${props =>
    props.compact ? 'translate(0, 0)' : 'translate(-50%, -50%)'};
  }
  @media (max-width: 767px) {
    top: 0;
    width: 100%;
  }
`;
export const ControlHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: ${Colors.primary80};

  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
`;
export const HeaderContent = styled.div`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  color: ${Colors.white};
`;
export const ExpandedStatusContainer = styled.div`
  background-color: ${Colors.white};
  padding: 1em;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  max-height: 85vh;
  overflow-y: auto;
  @media (max-width: 480px) {
  }
`;

export const CargoIssuesStatus = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.terra_cotta};
  text-align: center;
  border: 1px solid ${Colors.terra_cotta};
  padding: 0.594rem 0.5rem;
  width: 100%;
`;

export const CargoSuccessStatus = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.moss_green};
  text-align: center;
  border: 1px solid ${Colors.moss_green};
  padding: 0.594rem 0.5rem;
  width: 100%;
`;

interface ICheck {
  firstCircle?: boolean;
  check?: boolean;
}

export const Step = styled.div`
  width: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Circle = styled.div<ICheck>`
  width: ${props => (props.firstCircle || props.check ? '16px' : '8px')};
  height: ${props => (props.firstCircle || props.check ? '16px' : '8px')};
  border: 2px solid ${Colors.moss_green};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &.after-highlighted {
    width: 1rem;
    height: 1rem;
    border: 2px solid ${Colors.periwinkle};
  }
`;
export const InnerCircle = styled.div<ICheck>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => (props.check ? Colors.white : Colors.white)};

`;
export const Line = styled.div`
  height: 3.5em;
  width: 0.125em;
  background: ${Colors.moss_green};

  &.after-highlighted {
    background: ${Colors.periwinkle};
  }
`;
export const DescriptionWrapper = styled.div`
  position: relative;
  left: 1.875rem;
  top: -0.2rem;
  color: ${Colors.primary80};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
`;
export const Description = styled.div<ICheck>`
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  color: ${'inherit'};
`;
export const Date = styled.div<ICheck>`
  font-size: 0.75em;
  font-weight: ${props => (props.check ? 600 : 500)};
  line-height: ${props => (props.check ? '1.125em' : '0.75em')};

  color: ${props => (props.check ? Colors.moss_green : 'inherit')};
`;

export const StepperContainer = styled.div`
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const Stepper = styled.div`
  margin-top: 0.75em;
  margin-bottom: 0.75em;
`;
type Props = {
  data: any;
  idx: number;
  length: number;
  statuses: any[];
};
export enum TypesStatus {
  LOGS = "logs",
  PLANNED = "planned",

}

const Wrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
`;

const StatusIconWrapper = styled.div<{ type: TypesStatus }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  background-color: ${({ type }) =>
    type !== TypesStatus.PLANNED ? '#EF6051' : '#F6C1C1'};
`;


const VerticalLine = styled.div<{ type: TypesStatus }>`
  position: absolute;
  height: 2.0rem;
  width: 2px;
  z-index: 0;
  background-color: ${({ type }) =>
    type !== TypesStatus.PLANNED ? '#EF6051' : '#F6C1C1'};
  left: 0.5625rem; 
  top: 70%;
`;



const DateText = styled.div<ICheck>`
  font-size: 0.625rem;
  color: blue;
  font-style: italic;
   line-height: 1.5em;

  color: ${Colors.primary80};
`;

const StatusHistory: FC<Props> = ({ data, idx, length, statuses }) => {
  // const { t } = useTranslation();
  console.log('data', statuses);
  const isHighlighted = data.type === TypesStatus.LOGS
  const [isDeparture, setIsDeparture] = useState<null | boolean>(data.is_departure_country);

  const renderStatusText = () => {
    if (isDeparture === true) {
      return `${statuses.find((item) => item.value === data.country_from)?.label}.${data.status_data?.name_ua || ''}`;
    }
    if (isDeparture === false) {
      return `${statuses.find((item) => item.value === data.country_to)?.label}.${data.status_data?.name_ua || ''}`;
    }
    return `${data.status_data?.name_ua || ''}`;
  };

  return (
    <StepperContainer key={idx}>
      <DescriptionWrapper>
        <Description check={data.type === TypesStatus.LOGS}>{renderStatusText()}</Description>
        <DateText check={data.type === TypesStatus.LOGS}>{data?.time?.slice(0, 10).split('-').reverse().join('.')}</DateText>
      </DescriptionWrapper>
      <StatusIconWrapper type={data.type}>
        {!(length - 1 === idx) && <VerticalLine type={data.type} />}
        <InnerCircle check={data.type === TypesStatus.LOGS} />
      </StatusIconWrapper>

    </StepperContainer>


  );
};

export default StatusHistory;
