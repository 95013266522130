export const contriesData = [
    { "value": "AU", "label": "Австралія" },
    { "value": "AT", "label": "Австрія" },
    { "value": "AZ", "label": "Азербайджан" },
    { "value": "AX", "label": "Аландські Острови" },
    { "value": "AL", "label": "Албанія" },
    { "value": "DZ", "label": "Алжир" },
    { "value": "AS", "label": "Американське Самоа" },
    { "value": "AI", "label": "Ангілья" },
    { "value": "AO", "label": "Ангола" },
    { "value": "AD", "label": "Андорра" },
    { "value": "AQ", "label": "Антарктика" },
    { "value": "AG", "label": "Антигуа і Барбуда" },
    { "value": "MO", "label": "Макао" },
    { "value": "AR", "label": "Аргентина" },
    { "value": "AM", "label": "Вірменія" },
    { "value": "AW", "label": "Аруба" },
    { "value": "AF", "label": "Афганістан" },
    { "value": "BS", "label": "Багамські Острови" },
    { "value": "BD", "label": "Бангладеш" },
    { "value": "BB", "label": "Барбадос" },
    { "value": "BH", "label": "Бахрейн" },
    { "value": "BZ", "label": "Беліз" },
    { "value": "BE", "label": "Бельгія" },
    { "value": "BJ", "label": "Бенін" },
    { "value": "BM", "label": "Бермудські Острови" },
    { "value": "BY", "label": "Білорусь" },
    { "value": "BG", "label": "Болгарія" },
    { "value": "BO", "label": "Болівія" },
    { "value": "BA", "label": "Боснія і Герцеговина" },
    { "value": "BW", "label": "Ботсвана" },
    { "value": "BR", "label": "Бразилія" },
    { "value": "IO", "label": "Британська Територія в Індійському Океані" },
    { "value": "VG", "label": "Британські Віргінські Острови" },
    { "value": "BN", "label": "Бруней-Даруссалам" },
    { "value": "BF", "label": "Буркіна-Фасо" },
    { "value": "BI", "label": "Бурунді" },
    { "value": "BT", "label": "Бутан" },
    { "value": "VU", "label": "Вануату" },
    { "value": "VA", "label": "Ватикан" },
    { "value": "GB", "label": "Великобританія" },
    { "value": "VE", "label": "Венесуела" },
    { "value": "VI", "label": "Віргінські Острови (США)" },
    { "value": "WF", "label": "Волліс і Футуна" },
    { "value": "VN", "label": "В'єтнам" },
    { "value": "UM", "label": "Зовнішні Віддалені Острови (США)" },
    { "value": "GA", "label": "Габон" },
    { "value": "HT", "label": "Гаїті" },
    { "value": "GY", "label": "Гаяна" },
    { "value": "GM", "label": "Гамбія" },
    { "value": "GH", "label": "Гана" },
    { "value": "GP", "label": "Гваделупа" },
    { "value": "GT", "label": "Гватемала" },
    { "value": "GF", "label": "Гвіана" },
    { "value": "GN", "label": "Гвінея" },
    { "value": "GW", "label": "Гвінея-Бісау" },
    { "value": "GG", "label": "Гернсі" },
    { "value": "GI", "label": "Гібралтар" },
    { "value": "HN", "label": "Гондурас" },
    { "value": "HK", "label": "Гонконг" },
    { "value": "GD", "label": "Гренада" },
    { "value": "GR", "label": "Греція" },
    { "value": "GE", "label": "Грузія" },
    { "value": "GU", "label": "Гуам" },
    { "value": "GL", "label": "Гренландія" },

    {
        "value": "GP",
        "label": "Гваделупа"
    },
    {
        "value": "GT",
        "label": "Гватемала"
    },
    {
        "value": "GF",
        "label": "Гвіана"
    },
    {
        "value": "GN",
        "label": "Гвінея"
    },
    {
        "value": "GW",
        "label": "Гвінея-Бісау"
    },
    {
        "value": "GG",
        "label": "Гернсі"
    },
    {
        "value": "GI",
        "label": "Гібралтар"
    },
    {
        "value": "HN",
        "label": "Гондурас"
    },
    {
        "value": "HK",
        "label": "Гонконг"
    },
    {
        "value": "GD",
        "label": "Гренада"
    },
    {
        "value": "GR",
        "label": "Греція"
    },
    {
        "value": "GE",
        "label": "Грузія"
    },
    {
        "value": "GU",
        "label": "Гуам"
    },
    {
        "value": "GL",
        "label": "Гренландія"
    },
    {
        "value": "DK",
        "label": "Данія"
    },
    {
        "value": "JE",
        "label": "Джерсі"
    },
    {
        "value": "DJ",
        "label": "Джибуті"
    },
    {
        "value": "DM",
        "label": "Домініка"
    },
    {
        "value": "DO",
        "label": "Домініканська Республіка"
    },
    {
        "value": "CD",
        "label": "Демократична Республіка Конго"
    },
    {
        "value": "EC",
        "label": "Еквадор"
    },
    {
        "value": "GQ",
        "label": "Екваторіальна Гвінея"
    },
    {
        "value": "ER",
        "label": "Еритрея"
    },
    {
        "value": "EE",
        "label": "Естонія"
    },
    {
        "value": "ET",
        "label": "Ефіопія"
    },
    {
        "value": "EG",
        "label": "Єгипет"
    },
    {
        "value": "YE",
        "label": "Ємен"
    },
    {
        "value": "ZM",
        "label": "Замбія"
    },
    {
        "value": "ZW",
        "label": "Зімбабве"
    },
    {
        "value": "IL",
        "label": "Ізраїль"
    },
    {
        "value": "IN",
        "label": "Індія"
    },
    {
        "value": "ID",
        "label": "Індонезія"
    },
    {
        "value": "IQ",
        "label": "Ірак"
    },
    {
        "value": "IR",
        "label": "Іран"
    },
    {
        "value": "IE",
        "label": "Ірландія"
    },
    {
        "value": "IS",
        "label": "Ісландія"
    },
    {
        "value": "ES",
        "label": "Іспанія"
    },
    {
        "value": "IT",
        "label": "Італія"
    },
    {
        "value": "JO",
        "label": "Йорданія"
    },
    {
        "value": "CV",
        "label": "Кабо-Верде"
    },
    {
        "value": "KZ",
        "label": "Казахстан"
    },
    {
        "value": "KY",
        "label": "Кайманові Острови"
    },
    {
        "value": "KH",
        "label": "Камбоджа"
    },
    {
        "value": "CM",
        "label": "Камерун"
    },
    {
        "value": "CA",
        "label": "Канада"
    },
    {
        "value": "BQ",
        "label": "Карибські Нідерланди"
    },
    {
        "value": "QA",
        "label": "Катар"
    },
    {
        "value": "KE",
        "label": "Кенія"
    },
    {
        "value": "CY",
        "label": "Кіпр"
    },
    {
        "value": "KI",
        "label": "Кірибаті"
    },
    {
        "value": "KG",
        "label": "Киргизстан"
    }, {
        "value": "CN",
        "label": "Китай"
    },
    {
        "value": "CC",
        "label": "Кокосові (Кілінгові) Острови"
    },
    {
        "value": "CO",
        "label": "Колумбія"
    },
    {
        "value": "KM",
        "label": "Коморські Острови"
    },
    {
        "value": "CG",
        "label": "Конго"
    },
    {
        "value": "KP",
        "label": "КНДР (Північна Корея)"
    },
    {
        "value": "KR",
        "label": "Південна Корея"
    },
    {
        "value": "CR",
        "label": "Коста-Рика"
    },
    {
        "value": "CI",
        "label": "Кот-д'Івуар"
    },
    {
        "value": "CU",
        "label": "Куба"
    },
    {
        "value": "KW",
        "label": "Кувейт"
    },
    {
        "value": "CW",
        "label": "Кюрасао"
    },
    {
        "value": "LA",
        "label": "Лаос"
    },
    {
        "value": "LV",
        "label": "Латвія"
    },
    {
        "value": "LS",
        "label": "Лесото"
    },
    {
        "value": "LR",
        "label": "Ліберія"
    },
    {
        "value": "LB",
        "label": "Ліван"
    },
    {
        "value": "LY",
        "label": "Лівія"
    },
    {
        "value": "LT",
        "label": "Литва"
    },
    {
        "value": "LI",
        "label": "Ліхтенштейн"
    },
    {
        "value": "LU",
        "label": "Люксембург"
    },
    {
        "value": "MU",
        "label": "Маврикій"
    },
    {
        "value": "MR",
        "label": "Мавританія"
    },
    {
        "value": "MG",
        "label": "Мадагаскар"
    },
    {
        "value": "YT",
        "label": "Майотта"
    },
    {
        "value": "MO",
        "label": "Макао"
    },
    {
        "value": "MW",
        "label": "Малаві"
    },
    {
        "value": "MY",
        "label": "Малайзія"
    },
    {
        "value": "ML",
        "label": "Малі"
    },
    {
        "value": "MV",
        "label": "Мальдіви"
    },
    {
        "value": "MT",
        "label": "Мальта"
    },
    {
        "value": "MA",
        "label": "Марокко"
    },
    {
        "value": "MQ",
        "label": "Мартиніка"
    },
    {
        "value": "MH",
        "label": "Маршаллові Острови"
    },
    {
        "value": "MX",
        "label": "Мексика"
    },
    {
        "value": "FM",
        "label": "Мікронезія"
    },
    {
        "value": "MZ",
        "label": "Мозамбік"
    },
    {
        "value": "MD",
        "label": "Молдова"
    },
    {
        "value": "MC",
        "label": "Монако"
    },
    {
        "value": "MN",
        "label": "Монголія"
    },
    {
        "value": "MS",
        "label": "Монтсеррат"
    },
    {
        "value": "MM",
        "label": "М'янма (Бірма)"
    },
    {
        "value": "NA",
        "label": "Намібія"
    },
    {
        "value": "NR",
        "label": "Науру"
    },
    {
        "value": "NP",
        "label": "Непал"
    },
    {
        "value": "NE",
        "label": "Нігер"
    },
    {
        "value": "NG",
        "label": "Нігерія"
    },
    {
        "value": "NL",
        "label": "Нідерланди"
    },
    {
        "value": "NI",
        "label": "Нікарагуа"
    },
    {
        "value": "NZ",
        "label": "Нова Зеландія"
    },
    {
        "value": "NU",
        "label": "Ніуе"
    },
    {
        "value": "NO",
        "label": "Норвегія"
    },
    {
        "value": "AE",
        "label": "ОАЕ"
    },
    {
        "value": "OM",
        "label": "Оман"
    },
    {
        "value": "BV",
        "label": "Острів Буве"
    },
    {
        "value": "IM",
        "label": "Острів Мен"
    },
    {
        "value": "CX",
        "label": "Острів Різдва"
    },
    {
        "value": "SH",
        "label": "Острів Святої Єлени"
    },
    {
        "value": "CK",
        "label": "Острови Кука"
    },
    {
        "value": "TC",
        "label": "Острови Теркс і Кайкос"
    },
    {
        "value": "PK",
        "label": "Пакистан"
    },
    {
        "value": "PW",
        "label": "Палау"
    },
    {
        "value": "PS",
        "label": "Палестинські Території"
    },
    {
        "value": "PA",
        "label": "Панама"
    },
    {
        "value": "PG",
        "label": "Папуа-Нова Гвінея"
    },
    {
        "value": "PY",
        "label": "Парагвай"
    },
    {
        "value": "PE",
        "label": "Перу"
    },
    {
        "value": "PL",
        "label": "Польща"
    },
    {
        "value": "PT",
        "label": "Португалія"
    },
    {
        "value": "PR",
        "label": "Пуерто-Рико"
    },
    {
        "value": "RE",
        "label": "Реюньйон"
    },
    {
        "value": "RW",
        "label": "Руанда"
    },
    {
        "value": "RO",
        "label": "Румунія"
    },
    {
        "value": "SV",
        "label": "Сальвадор"
    },
    {
        "value": "WS",
        "label": "Самоа"
    },
    {
        "value": "SM",
        "label": "Сан-Марино"
    },
    {
        "value": "ST",
        "label": "Сан-Томе і Принсіпі"
    },
    {
        "value": "SA",
        "label": "Саудівська Аравія"
    },
    {
        "value": "SC",
        "label": "Сейшельські Острови"
    },
    {
        "value": "BL",
        "label": "Сен-Бартелемі"
    },
    {
        "value": "MF",
        "label": "Сен-Мартен"
    },
    {
        "value": "PM",
        "label": "Сен-П'єр і Мікелон"
    },
    {
        "value": "SN",
        "label": "Сенегал"
    },
    {
        "value": "VC",
        "label": "Сент-Вінсент і Гренадини"
    },
    {
        "value": "KN",
        "label": "Сент-Кітс і Невіс"
    },
    {
        "value": "LC",
        "label": "Сент-Люсія"
    },
    {
        "value": "RS",
        "label": "Сербія"
    },
    {
        "value": "SG",
        "label": "Сінгапур"
    },
    {
        "value": "SX",
        "label": "Сінт-Мартен"
    },
    {
        "value": "SY",
        "label": "Сирія"
    },
    {
        "value": "SK",
        "label": "Словаччина"
    },
    {
        "value": "SI",
        "label": "Словенія"
    },
    {
        "value": "SB",
        "label": "Соломонові Острови"
    },
    {
        "value": "SO",
        "label": "Сомалі"
    },
    {
        "value": "SD",
        "label": "Судан"
    },
    {
        "value": "SR",
        "label": "Суринам"
    },
    {
        "value": "US",
        "label": "США"
    },
    {
        "value": "SL",
        "label": "Сьєрра-Леоне"
    },
    {
        "value": "TJ",
        "label": "Таджикистан"
    },
    {
        "value": "TH",
        "label": "Таїланд"
    },
    {
        "value": "TZ",
        "label": "Танзанія"
    },
    {
        "value": "TG",
        "label": "Того"
    },
    {
        "value": "TK",
        "label": "Токелау"
    },
    {
        "value": "TO",
        "label": "Тонга"
    },
    {
        "value": "TT",
        "label": "Тринідад і Тобаго"
    },
    {
        "value": "TV",
        "label": "Тувалу"
    },
    {
        "value": "TN",
        "label": "Туніс"
    },
    {
        "value": "TM",
        "label": "Туркменістан"
    },
    {
        "value": "TR",
        "label": "Туреччина"
    },
    {
        "value": "UG",
        "label": "Уганда"
    },
    {
        "value": "HU",
        "label": "Угорщина"
    },
    {
        "value": "UZ",
        "label": "Узбекистан"
    },
    {
        "value": "UA",
        "label": "Україна"
    },
    {
        "value": "WF",
        "label": "Уолліс і Футуна"
    },
    {
        "value": "UY",
        "label": "Уругвай"
    },
    {
        "value": "FO",
        "label": "Фарерські Острови"
    },
    {
        "value": "FJ",
        "label": "Фіджі"
    },
    {
        "value": "PH",
        "label": "Філіппіни"
    },
    {
        "value": "FI",
        "label": "Фінляндія"
    },
    {
        "value": "FK",
        "label": "Фолклендські Острови"
    },
    {
        "value": "FR",
        "label": "Франція"
    },
    {
        "value": "GF",
        "label": "Французька Гвіана"
    },
    {
        "value": "PF",
        "label": "Французька Полінезія"
    },
    {
        "value": "TF",
        "label": "Французькі Південні Території"
    },
    {
        "value": "HR",
        "label": "Хорватія"
    },
    {
        "value": "CF",
        "label": "Центральноафриканська Республіка"
    },
    {
        "value": "TD",
        "label": "Чад"
    },
    {
        "value": "ME",
        "label": "Чорногорія"
    },
    {
        "value": "CZ",
        "label": "Чехія"
    },
    {
        "value": "CL",
        "label": "Чилі"
    },
    {
        "value": "CH",
        "label": "Швейцарія"
    },
    {
        "value": "SE",
        "label": "Швеція"
    },
    {
        "value": "SJ",
        "label": "Шпіцберген і Ян-Маєн"
    },
    {
        "value": "LK",
        "label": "Шрі-Ланка"
    },
    {
        "value": "EC",
        "label": "Еквадор"
    },
    {
        "value": "GQ",
        "label": "Екваторіальна Гвінея"
    },
    {
        "value": "ER",
        "label": "Еритрея"
    },
    {
        "value": "SZ",
        "label": "Есватіні"
    },
    {
        "value": "EE",
        "label": "Естонія"
    },
    {
        "value": "ET",
        "label": "Ефіопія"
    },
    {
        "value": "ZA",
        "label": "Південно-Африканська Республіка"
    },
    {
        "value": "SS",
        "label": "Південний Судан"
    },
    {
        "value": "JM",
        "label": "Ямайка"
    },
    {
        "value": "JP",
        "label": "Японія"
    }


]
