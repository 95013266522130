import { GridPriceOfferTitle, GridPriceOfferWrapper } from './styles';
import { formatCurrency } from '../../utils/global';

const GridPriceOffers = ({ data }: any) => {
  return data?.map((item: any, index: number) => (
    <GridPriceOfferWrapper key={index}>
      <GridPriceOfferTitle>Цінова {++index}</GridPriceOfferTitle>
      <p>{formatCurrency(item?.total)}</p>
    </GridPriceOfferWrapper>
  ));
};

export default GridPriceOffers;
