import React, { useEffect, useMemo, useState } from 'react';
import {
  Circle,
  Description,
  DescriptionWrapper,
  InnerCircle,
  Line,
  Step,
  StepperContainer,
  Stepper,
} from './styles';
import { useDetailsContext } from '../Threads/DetailsContext';

export interface StepDelivery {
  date: string;
  description: string;
  isHighlighted?: boolean;
}

interface IVerticalOrderStepper {
  compact?: boolean;
  statusTransportation?: string;
}

const VerticalOrderStepper = ({ compact, statusTransportation }: IVerticalOrderStepper) => {
  const { statusesStack, config, activeStatusIndexData } = useDetailsContext();
  const highlightedIndex = useMemo(
    () => statusesStack.findLastIndex((step: any) => step.isHighlighted),
    [statusesStack, config?.status],
  );


  const getClassNames = (isHighlighted: boolean | undefined, idx: number) => {
    const baseClass = isHighlighted ? 'highlighted' : '';
    const afterClass = idx > highlightedIndex ? 'after-highlighted' : '';
    return `${baseClass} ${afterClass}`;
  };

  const start = Math.max(highlightedIndex - 1, 0);
  const end = Math.min(highlightedIndex + 2, statusesStack.length);
  const displayedSteps = statusesStack?.slice(start, end);

  const isCompact = compact ? displayedSteps : statusesStack;

  return (
    <Stepper>
      {
        <>
          {isCompact?.map((step: any, idx: any) => {
            const { label, isHighlighted } = step;
            const circleClassNames = getClassNames(isHighlighted, idx);
            const lineClassNames =
              idx > highlightedIndex - 1 ? 'after-highlighted' : '';

            return (
              <StepperContainer key={idx}>
                <DescriptionWrapper>
                  <Description check={isHighlighted}>{label}</Description>
                </DescriptionWrapper>
                <Step>
                  <Circle
                    firstCircle={idx === 0}
                    check={isHighlighted}
                    className={circleClassNames}
                  >
                    <InnerCircle
                      check={isHighlighted}
                      className={circleClassNames}
                    />
                  </Circle>
                  {idx < isCompact.length - 1 && (
                    <Line className={lineClassNames} />
                  )}
                </Step>
              </StepperContainer>
            );
          })}
        </>

      }
    </Stepper>
  );
};

export default VerticalOrderStepper;
