import { useState } from 'react';
import ExpandCloseSvg from '../../assets/ReactSvg/ShippingIcon/ExpandCloseSvg';
import ExpandDownSvg from '../../assets/ReactSvg/ShippingIcon/ExpandDownSvg';
import ExpandSvg from '../../assets/ReactSvg/ShippingIcon/ExpandSvg';
import ShippingCheckmarkSvg from '../../assets/ReactSvg/ShippingIcon/ShippingCheckmarkSvg';
import { useMobile, useTablet } from '../../constants/breakpoints';
import {
  CargoIssuesStatus,
  CargoSuccessStatus,
  ControlHeader,
  ExpandContainer,
  ExpandedOrderTrackingContainer,
  ExpandedStatusContainer,
  ExpandHeaderWrapper,
  ExpandWrapper,
  HeaderContent,
  Label,
  OrderTrackingContainer,
  Status,
  StatusContainer,
  StatusDate,
  StatusText,
  StepperContainer,
} from './styles';
import VerticalOrderStepper from './VerticalStepper';
import { useDetailsContext } from '../Threads/DetailsContext';
import { getCurrentStatusLabel } from '../Details/utils';
import { SvgWrapper } from '../Details/styles';
import { Colors } from '../colors';
import { formatTransportationStatus } from '../../utils/global';
import { Modal } from '@mui/material';
import StatusHistory from './StatusHistory';
import { contriesData } from '../../utils/countries_ua';

const OrderTracking = ({ status }: any) => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isDesktop = !isMobile && !isTablet;
  const {
    setIsExpandModalOpen,
    isExpandModalOpen,
    isCompactMode,
    setIsCompactMode,
    config,
    statusesStack,
  } = useDetailsContext();
  const handleModalOpen = () => {
    setIsCompactMode(false);
    setIsExpandModalOpen(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflow = 'hidden';
    document.body.style.maxHeight = '100vh';
  };
  const handleModalClose = () => {
    setIsExpandModalOpen(false);
    setIsCompactMode(false);
    document.body.style.overflow = '';
    document.body.style.maxHeight = '';
  };
  const handleModalCompactToogler = () => {
    if (isExpandModalOpen && !isCompactMode) {
      return;
    }
    setIsCompactMode(!isCompactMode);
    setIsExpandModalOpen(!isExpandModalOpen);
  };
  return (
    <>
      <StepperContainer>
        <OrderTrackingContainer>
          {isDesktop && <Label>Відстеженння відправлення:</Label>}
          <StatusContainer>
            <ExpandContainer>
              <ExpandHeaderWrapper>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ShippingCheckmarkSvg />
                </div>
                <Status>
                  <StatusText>
                    {config?.status_data !== null ? config?.status_data?.name_ua : formatTransportationStatus(config?.status)}

                  </StatusText>
                </Status>
              </ExpandHeaderWrapper>

              <ExpandWrapper>
                <SvgWrapper>
                  <ExpandSvg
                    width={'1.5rem'}
                    height={'1.5rem'}
                    style={{ cursor: 'pointer' }}
                    onClick={handleModalOpen}
                  />
                </SvgWrapper>
                {isDesktop && (
                  <SvgWrapper>
                    <ExpandDownSvg
                      width={'1.5rem'}
                      height={'1.5rem'}
                      style={{
                        cursor: 'pointer',
                        transform: isCompactMode
                          ? 'rotate(3.142rad)'
                          : 'rotate(0)',
                        transition: '0.5s',
                      }}
                      onClick={handleModalCompactToogler}
                    />
                  </SvgWrapper>
                )}
              </ExpandWrapper>
            </ExpandContainer>
          </StatusContainer>
        </OrderTrackingContainer>

        {isExpandModalOpen && isCompactMode && !isMobile && (
          <ExpandedOrderTrackingContainer compact={isCompactMode}>
            {!isCompactMode && (
              <ControlHeader>
                <HeaderContent>Відстеження відправлення</HeaderContent>
                <ExpandCloseSvg
                  style={{ cursor: 'pointer' }}
                  onClick={handleModalClose}
                />
              </ControlHeader>
            )}
            {/* <ExpandedStatusContainer>
              {status === 'cargo_issues' ? (
                <CargoIssuesStatus>Проблеми з вантажем</CargoIssuesStatus>
              ) : status === 'tariff_lost' ? (
                <CargoIssuesStatus>Відхилено тариф</CargoIssuesStatus>
              ) : status === 'rejected' ? (
                <CargoIssuesStatus>Відхилено</CargoIssuesStatus>
              ) : status === 'received' ? (
                <CargoSuccessStatus>Отримано</CargoSuccessStatus>
              ) : (
                <VerticalOrderStepper
                  compact={isCompactMode}
                  statusTransportation={status}
                />
              )}
            </ExpandedStatusContainer> */}
            <ExpandedStatusContainer>

              {config.status_history && config.status_history?.length > 0
                ?
                config.status_history?.map((item: any, index: number, arr: any[]) => (
                  <StatusHistory
                    data={item}
                    key={index}
                    idx={index}
                    length={arr.length}
                    statuses={contriesData}
                  />
                ))
                :
                <>
                  {config?.status === 'cargo_issues' ? (
                    <CargoIssuesStatus>Проблеми з вантажем</CargoIssuesStatus>
                  ) : config?.status === 'tariff_lost' ? (
                    <CargoIssuesStatus>Відхилено тариф</CargoIssuesStatus>
                  ) : config?.status === 'rejected' ? (
                    <CargoIssuesStatus>Відхилено</CargoIssuesStatus>
                  ) : config?.status === 'received' ? (
                    <CargoSuccessStatus>Отримано</CargoSuccessStatus>
                  ) : (

                    <VerticalOrderStepper statusTransportation={config?.status} />
                  )}
                </>
              }

              {/* )} */}
            </ExpandedStatusContainer>
          </ExpandedOrderTrackingContainer>
        )}

      </StepperContainer>
    </>
  );
};

export default OrderTracking;
